import request from '@/util/request'
// 采购统计接口  czy
export function orders(data){
    return request({
        url: '/cai-gou-dan/orders',
        method: 'post',
        data
    })
}
// 供货商信息多选列表  czy
export function getGongHuoShangList() {
    return request({
        url: '/gong-huo-shang/get-gong-huo-shang-list',
        method: 'post',
    })
}


//基础列表
export function list(data) {
    return request({
        url: '/cai-gou-dan/list',
        method: 'post',
        data
    })
}

//添加保存
export function add(data) {
    return request({
        url: '/cai-gou-dan/add',
        method: 'post',
        data
    })
}

//获取编辑页数据
export function getUpdate(id) {
    return request({
        url: `/cai-gou-dan/get-update/${id}`,
        method: 'get',
    })
}

//获取详情页数据
export function getDetail(id) {
    return request({
        url: `/cai-gou-dan/get-detail/${id}`,
        method: 'get',
    })
}

//编辑保存
export function update(data) {
    return request({
        url: '/cai-gou-dan/update',
        method: 'post',
        data
    })
}



//删除
export function del(id) {
    return request({
        url: `/cai-gou-dan/delete/${id}`,
        method: 'post',
    })
}



//批量删除
export function deleteBatch(data) {
    return request({
        url: '/cai-gou-dan/delete-batch',
        method: 'post',
        data
    })
}

//独立查询
export function query(data) {
    return request({
        url: '/cai-gou-dan/query',
        method: 'post',
        data
    })
}


//删除
export function toQueRen(id) {
    return request({
        url: `/cai-gou-dan/queRen/${id}`,
        method: 'post',
    })
}

//入库
export function updateRuKu(data) {
    return request({
        url: '/cai-gou-dan/update-ru-ku',
        method: 'post',
        data
    })
}
//全部入库
export function updateAllRuKu(data) {
    return request({
        url: '/cai-gou-dan/update-all-ru-ku',
        method: 'post',
        data
    })
}
/*
列表路由
{
    path: 'cai-gou-dan-list',
    component: () => import('@/view/cggl/cgd/CaiGouDanList'),
    name: 'CaiGouDanList',
    meta: {title: '采购单', isTag: true, isMenu: true, searchable: true}
}

独立查询路由
{
    path: 'cai-gou-dan-query',
    component: () => import('@/view/cggl/cgd/CaiGouDanQuery'),
    name: 'CaiGouDanQuery',
    meta: {title: '采购单查询', isTag: true, isMenu: true, searchable: true}
}

提醒列表
{
    path: 'cai-gou-dan-remind-list',
    component: () => import('@/view/cggl/cgd/CaiGouDanRemindList'),
    name: 'CaiGouDanRemindList',
    meta: {title: '采购单提醒', isTag: true, isMenu: true, searchable: true}
}

菜单识别:基础相关-list为入口
[
{"name":"采购单基础列表","methodUrl":"/cai-gou-dan/list","operateType":"search","sort":1,"functionClassification":"0","_key":"1"},
{"name":"采购单添加保存","methodUrl":"/cai-gou-dan/add","operateType":"add","sort":2,"functionClassification":"0","_key":"2"},
{"name":"采购单获取编辑数据","methodUrl":"/cai-gou-dan/get-update/{id}","operateType":"search","sort":3,"functionClassification":"0","_key":"3"},
{"name":"采购单获取详情数据","methodUrl":"/cai-gou-dan/get-detail/{id}","operateType":"search","sort":4,"functionClassification":"0","_key":"4"},
{"name":"采购单编辑保存","methodUrl":"/cai-gou-dan/update","operateType":"edit","sort":5,"functionClassification":"0","_key":"5"},
{"name":"采购单删除","methodUrl":"/cai-gou-dan/delete/{id}","operateType":"delete","sort":6,"functionClassification":"0","_key":"6"},
{"name":"采购单批量删除","methodUrl":"/cai-gou-dan/delete-batch","operateType":"delete","sort":7,"functionClassification":"0","_key":"7"}
]
菜单识别:独立查询-query为入口
[
{"name":"采购单独立查询","methodUrl":"/cai-gou-dan/query","operateType":"search","sort":11,"functionClassification":"0","_key":"11"}
]
*/
