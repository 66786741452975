import { render, staticRenderFns } from "./CaiGouDanList.vue?vue&type=template&id=0f209028&scoped=true&"
import script from "./CaiGouDanList.vue?vue&type=script&lang=js&"
export * from "./CaiGouDanList.vue?vue&type=script&lang=js&"
import style0 from "./CaiGouDanList.vue?vue&type=style&index=0&id=0f209028&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0f209028",
  null
  
)

export default component.exports