<template>
    <div class="x-page-container" ref="resize">
        <!-- 查询区 -->
        <div class="x-page-search">
            <x-search-item label="采购单ID">
                <el-input v-model="search.caiGouDanID" size="small"/>
            </x-search-item>
            <x-search-item label="供货商">
                <el-select v-model="search.gongHuoShang" size="small" maxlength="36" show-word-limit>
                    <el-option v-for="item in gongHuoShangOptions" :key="item.gongHuoShang" :label="item.gongHuoShang"
                               :value="item.gongHuoShangMC"></el-option>
                </el-select>
            </x-search-item>
            <x-search-item label="采购类型">
                <x-selector-one v-model="search.caiGouLX" dictType="T_CAI_GOU_TYPE"/>
            </x-search-item>
            <x-search-item label="仓库">
                <el-select v-model="search.cangKu" size="small">
                    <el-option v-for="item in options" :key="item.cangKu" :label="item.cangKu"
                               :value="item.cangKuMC"></el-option>
                </el-select>
            </x-search-item>
            <x-search-item label="采购时间" textWidth="230px" hasEnd>
                <el-date-picker v-model="search.caiGouSJBegin" size="small" type="datetime"/>
                <el-date-picker v-model="search.caiGouSJEnd" size="small" type="datetime" slot="end"/>
            </x-search-item>
            <div class="x-search-item">
                <el-button type="primary" size="mini" @click="handleSearch">搜索</el-button>
                <el-button type="primary" size="mini" @click="handleClear">清空</el-button>
            </div>
        </div>        <!-- 批量按钮区 -->
        <div class="x-page-btn">
            <el-button type="success" size="mini" round @click="handleAdd">新增</el-button>
            <el-popconfirm title="数据删除后不可恢复，确认删除吗？" @confirm="handleDeleteBatch">
                <el-button type="danger" size="mini" round slot="reference">批量删除</el-button>
            </el-popconfirm>
        </div>
        <!--        统计区       -->
        <div>
            <el-row>
                <el-col :span="8">
                    <el-card>
                        <div slot="header" class="card-content">订单数</div>
                        <div slot="default" class="card-content">{{ totalOrders }}</div>
                    </el-card>
                </el-col>
                <el-col :span="8">
                    <el-card>
                        <div slot="header" class="card-content">采购商品数</div>
                        <div slot="default" class="card-content">{{ totalItems }}</div>
                    </el-card>
                </el-col>
                <el-col :span="8">
                    <el-card>
                        <div slot="header" class="card-content">采购金额</div>
                        <div slot="default" class="card-content">{{ totalAmount }}</div>
                    </el-card>
                </el-col>
            </el-row>
        </div>

        <!-- 列表区域 -->
        <el-table ref="table" v-loading="table.loading" :data="table.data" :height="table.height" border
                  @selection-change="handleSelectionChange">
            <el-table-column type="selection" width="40" fixed/>
            <el-table-column label="序号" type="index" fixed/>
            <el-table-column prop="caiGouDanID" label="采购单ID"/>
            <el-table-column prop="caoZuoRen" label="操作人"/>
            <el-table-column prop="caiGouLX" label="采购类型">
                <x-dict-show slot-scope="{row}" :code="row.caiGouLX" dictType="T_CAI_GOU_TYPE"/>
            </el-table-column>
            <el-table-column prop="caiGouSJ" label="采购时间"/>
            <el-table-column prop="caoZuoSJ" label="操作时间"/>
            <el-table-column prop="gongHuoShang" label="供货商"/>
            <el-table-column prop="cangKu" label="仓库"/>
            <el-table-column prop="heJi" label="合计（种）"/>
            <el-table-column prop="jinE" label="金额"/>
            <el-table-column prop="beiZhu" label="备注"/>
            <el-table-column prop="queRenStatus" label="确认状态"/>
            <el-table-column align="center" label="操作" width="300" fixed="right">
                <template slot-scope="{ row, $index }">
                    <el-button type="success" size="mini" round @click="handleToDetail(row.id)">查看</el-button>
                    <el-button type="success" size="mini" round @click="buFenRuKu(row.id)" v-show="row.queRenStatus === isQueRen && row.ruKuZT !==rkZT.YRK.key">部分入库</el-button>
                    <el-popconfirm title="是否确定全部入库？" @confirm="quanBuRuKu(row)">
                        <el-button type="success" size="mini" round slot="reference" v-show="row.queRenStatus === isQueRen && row.ruKuZT === rkZT.WRK.key">全部入库</el-button>
                    </el-popconfirm>
                    <el-button type="primary" v-show="row.queRenStatus !== isQueRen" size="mini" round @click="handleUpdate(row.id)">编辑</el-button>
                    <el-button type="info" v-show="row.queRenStatus !== isQueRen" size="mini" round @click="queRen(row.id)">确认</el-button>
                    <el-popconfirm title="数据删除后不可恢复，确认删除吗？" @confirm="handleDelete(row.id)">
                        <el-button type="danger" v-show="row.queRenStatus !== isQueRen" size="mini" round slot="reference">删除</el-button>
                    </el-popconfirm>
                </template>
            </el-table-column>
        </el-table>
        <!-- 分页区域 -->
        <el-pagination background
                       :total="table.total"
                       @size-change="handleSizeChange"
                       @current-change="handleCurrentChange"
                       layout="total, sizes, prev, pager, next, jumper"/>
        <!-- 编辑页区域 -->
        <edit ref="newEdit" :editor="newEditor" @submitted="refreshMsg"/>
        <ru-ku-edit ref="ruKuEdit" :editor="editor" @submitted="refreshMsg"/>
        <detail :detail="detail"/>

    </div>
</template>
<script>
    import * as service from "@/service/cggl/CaiGouDan";
    import {getCangKuList} from "@/service/cggl/TuiHuoDan";
    import XTableBase from "@/components/x/XTableBase";
    import Edit from "@/view/cggl/cgd/CaiGouDanEdit";
    import ruKuEdit from "@/view/cggl/cgd/CaiGouRuKuEdit";
    import Detail from "@/view/cggl/cgd/CaiGouDanDetail";
    import {getShangPinXXList} from "@/service/spgl/ShangPinXX";
    import {getGongHuoShangList} from "@/service/cggl/CaiGouDan";
    import {TYPE_RU_KU_STATUS} from "@/util/constant"

    export default {
        name: "CaiGouDanList",
        mixins: [XTableBase],
        components: {Edit, Detail, getShangPinXXList, ruKuEdit},
        data() {
            this.refreshService = service.list;
            this.service = service;
            return {
                options: [],
                gongHuoShangOptions: [],
                search: {
                    caiGouDanID: '',
                    caiGouSJBegin: '',
                    caiGouSJEnd: '',
                    gongHuoShang: '',
                    cangKu: '',
                    caiGouLX: '',
                },
                totalOrders: '',
                totalItems: '',
                totalAmount: '',
                isQueRen: '已确认',
                showChuKu: 'true',
                rkZT: TYPE_RU_KU_STATUS,
                newEditor: {
                    visible: false,
                    id: null,
                    isUpdate: false
                },
            }
        },
        mounted() {
            this.getCKList()
            this.getCardMsg()
            this.getGHSList()
        },
        methods: {
            quanBuRuKu(row) {
                this.service.updateAllRuKu(row).then((response) => {
                    //提示成功
                    this.$message.success(response.msg);
                    //刷新列表
                    this.refresh();
                });
            },
            handleAdd() {
                this.newEditor.isUpdate = false;
                this.newEditor.id = null;
                this.newEditor.visible = true;
            },
            handleUpdate(id) {
                this.newEditor.isUpdate = true;
                this.newEditor.id = id;
                this.newEditor.visible = true;
            },
            buFenRuKu(id) {
                this.editor.isUpdate = true;
                this.editor.id = id;
                this.editor.visible = true;
            },
            //采购单数据添加、编辑后刷新列表信息
            refreshMsg() {
                this.refresh()
                this.getCardMsg()
            },
            //获取设置卡片显示细信息
            getCardMsg() {
                service.orders().then(res => {
                    this.totalOrders = res.data.totalOrders
                    this.totalItems = res.data.totalItems
                    this.totalAmount = res.data.totalAmount
                })
            },
            // 获取仓库下拉选择列表
            getCKList() {
                getCangKuList().then((response) => {
                    this.options = response.data
                })
            },
            getGHSList() {
                getGongHuoShangList().then((response) => {
                    this.gongHuoShangOptions = response.data
                })
            },
            queRen(id) {
                service.toQueRen(id).then(res => {
                    if (res.code === 200) {
                        this.$message.success("该采购单已确认")
                        this.refresh()
                    }
                })
            },
        }
    }
</script>

<style scoped>
    .card-content {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 20px; /* 调整字体大小，可以根据需要修改数值 */
    }

    /*.x-page-stats {
      margin-bottom: 10px;
      text-align: right;
    }
    .x-page-stats span {
      margin-left: 10px;
    }*/
</style>
