<template>
    <el-dialog title="详情" :visible.sync="detail.visible" @open="open"
                destroy-on-close center append-to-body width="1000px">
        <x-detail label-width="140px" :entity="entity">
            <x-detail-item prop="caiGouDanID" label="采购单ID" />
            <x-detail-item prop="caoZuoRen" label="操作人" />
            <x-detail-item prop="caoZuoRenID" label="操作人ID" />
            <x-detail-item prop="caiGouLX" label="采购类型" >
                <x-dict-show :code="entity.caiGouLX" dictType="T_CAI_GOU_TYPE"/>
            </x-detail-item>
            <x-detail-item prop="caiGouSJ" label="采购时间" />
            <x-detail-item prop="caoZuoSJ" label="操作时间" />
            <x-detail-item prop="gongHuoShang" label="供货商" />
            <x-detail-item prop="gongHuoShangID" label="供货商ID" />
            <x-detail-item prop="cangKu" label="仓库" />
            <x-detail-item prop="cangKuID" label="仓库ID" />
            <x-detail-item prop="heJi" label="合计（种）" />
            <x-detail-item prop="jinE" label="金额" />
            <x-detail-item prop="beiZhu" label="备注" />
        </x-detail>
        <x-divider title="采购单详情"></x-divider>
        <el-table :data="entity.caiGouDanXiangQings" border class="x-detail-table">
            <el-table-column width="60" label="序号" prop="sort" />
            <el-table-column width="150" prop="caiGouDanID" label="采购单ID" />
            <el-table-column width="150" prop="shangPinMC" label="商品名称" />
            <el-table-column width="150" prop="shangPinID" label="商品ID" />
            <el-table-column width="150" prop="guiGe" label="规格" />
            <el-table-column width="150" prop="huoHao" label="货号" />
            <el-table-column width="150" prop="tiaoMa" label="条码" />
            <el-table-column width="150" prop="fenLei" label="分类" />
            <el-table-column width="150" prop="danWei" label="单位" />
            <el-table-column width="150" prop="shengChanRQ" label="生产日期" type="date" value-format="yyyy-MM-dd"/>
            <el-table-column width="150" prop="baoZhiQiTian" label="保质期（天）" />
            <el-table-column width="150" prop="caiGouSL" label="采购数量" />
            <el-table-column width="150" prop="yiRuKuSL" label="已入库数量" />
            <el-table-column width="150" prop="yiTuiHuoSL" label="已退货数量" />
            <el-table-column width="150" prop="jinHuoJiaHeJi" label="进货价合计" />
            <el-table-column width="150" prop="jinHuoJia" label="进货价" />
            <el-table-column width="150" prop="dianNeiLSJ" label="店内零售价" />
            <el-table-column width="150" prop="beiZhu" label="备注" />
        </el-table>
    </el-dialog>
</template>

<script>
    import {getDetail} from "@/service/cggl/CaiGouDan";
    import XDetailBase from "@/components/x/XDetailBase";

    export default {
        mixins: [XDetailBase],
        beforeCreate() {
            this.defaultModel = {
                caiGouDanID: "", // 采购单ID
                caoZuoRen: "", // 操作人
                caoZuoRenID: "", // 操作人ID
                caiGouLX: "", // 采购类型
                caiGouSJ: "", // 采购时间
                caoZuoSJ: "", // 操作时间
                gongHuoShang: "", // 供货商
                gongHuoShangID: "", // 供货商ID
                cangKu: "", // 仓库
                cangKuID: "", // 仓库ID
                heJi: "", // 合计（种）
                jinE: "", // 金额
                beiZhu: "", // 备注
                caiGouDanXiangQings: [],
            };
            this.getDetailService = getDetail;
        }
    }
</script>

<style scoped>

</style>