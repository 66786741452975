<template>
    <el-dialog :title="title" :visible.sync="editor.visible" @open="open" :close-on-click-modal="false"
               destroy-on-close center append-to-body width="80%">
        <el-form ref="form" :model="form" :rules="rules" class="x-edit">
<!--            <tr class="highlighted">
                <td colspan="3">(请选供货商后再搜索商品！)</td>
            </tr>-->
            <x-divider title="基本信息"></x-divider>
            <el-form-item label="操作人" v-show="false" prop="caoZuoRen">
                <el-input v-model="form.caoZuoRen" size="small" maxlength="36" show-word-limit/>
            </el-form-item>
            <el-form-item label="操作人ID" v-show="false" prop="caoZuoRenID">
                <el-input v-model="form.caoZuoRenID" size="small" maxlength="36" show-word-limit/>
            </el-form-item>
            <el-form-item label="采购类型" prop="caiGouLX">
                <el-select v-model="form.caiGouLX" placeholder="请选择采购类型" clearable maxlength="36" show-word-limit>
                    <el-option :label="cglx.XZCG.name" :value="cglx.XZCG.key"></el-option>
                    <el-option :label="cglx.BCGD.name" :value="cglx.BCGD.key"></el-option>
                    <el-option :label="cglx.QTCG.name" :value="cglx.QTCG.key"></el-option>
                </el-select>
            </el-form-item>

            <el-form-item label="采购时间" prop="caiGouSJ" v-if="showInputTime">
                <el-date-picker v-model="form.caiGouSJ" size="small" type="datetime"
                                value-format="yyyy-MM-dd HH:mm:ss"/>
            </el-form-item>
            <el-form-item label="供货商" prop="gongHuoShang">
                <el-select v-model="form.gongHuoShang" size="small" clearable maxlength="36" show-word-limit>
                    <el-option v-for="item in gongHuoShangOptions" :key="item.gongHuoShang" :label="item.gongHuoShang"
                               :value="item.gongHuoShangMC"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="采购仓库" prop="cangKu">
                <el-select v-model="form.cangKu" size="small" maxlength="36" clearable show-word-limit>
                    <el-option v-for="item in options" :key="item.cangKu" :label="item.cangKu"
                               :value="item.cangKuMC">
                    </el-option>
                </el-select>
            </el-form-item>

            <el-form-item label="备注" prop="beiZhu">
                <el-input v-model="form.beiZhu" size="small" maxlength="64" show-word-limit/>
            </el-form-item>

            <x-divider title="采购单详情"></x-divider>
            <x-selector
                    placement="center"
                    selector="ShangPinXXAllGuiGeSelector"
                    @selected="handleClSelect"
                    code-key="id"
                    selection
                    width="1000px">
                <el-button size="mini" type="success" round>添加商品</el-button>
            </x-selector>
            <x-row :rows="form.caiGouDanXiangQings" :rowModel="caiGouDanXiangQingsModel" no-add-btn>
                <x-row-cell title="商品名称" read="shangPinMC">
                </x-row-cell>
                <x-row-cell title="采购数量" prop="caiGouSL">
                    <el-input slot-scope="{row}" v-model="row.caiGouSL" size="small" maxlength="18"/>
                </x-row-cell>
                <x-row-cell title="参考进货价" prop="jinHuoJia">
                    <el-input slot-scope="{row}" v-model="row.jinHuoJia" size="small" maxlength="18"/>
                </x-row-cell>
                <x-row-cell title="生产日期" prop="shengChanRQ">
                    <el-input slot-scope="{row}" v-model="row.shengChanRQ" size="small" maxlength="8" type="date"
                              value-format="yyyy-MM-dd"/>
                </x-row-cell>
                <x-row-cell title="保质期（天）" prop="baoZhiQiTian">
                    <el-input slot-scope="{row}" v-model="row.baoZhiQiTian" size="small" maxlength="8"/>
                </x-row-cell>
                <x-row-cell title="规格" read="guiGe">
                </x-row-cell>
                <x-row-cell title="货号" read="huoHao">
                </x-row-cell>
                <x-row-cell title="条码" read="tiaoMa">
                </x-row-cell>
                <x-row-cell title="单位" read="danWei">
                </x-row-cell>
                <x-row-cell title="备注" prop="beiZhu">
                    <el-input slot-scope="{row}" v-model="row.beiZhu" size="small" maxlength="18"/>
                </x-row-cell>
            </x-row>

        </el-form>

        <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="save" icon="el-icon-document-add">保 存</el-button>
            <el-button @click="cancel" icon="el-icon-switch-button">取 消</el-button>
        </span>

    </el-dialog>


</template>

<script>
    import {add, update, getUpdate} from "@/service/cggl/CaiGouDan";
    import XEditBase from "@/components/x/XEditBase";
    import {ruleBuilder} from '@/util/validate';
    import {getCangKuList} from "@/service/cggl/TuiHuoDan";
    import {getGongHuoShangList} from "@/service/cggl/CaiGouDan";
    import {DICT_TYPE_CAI_GOU_TYPE, TYPE_RU_KU_STATUS} from "@/util/constant";
    import ShangPinXXAllGuiGeSelector from "@/view/selector/ShangPinXXAllGuiGeSelector";

    export default {
        mixins: [XEditBase],
        components: {ShangPinXXAllGuiGeSelector},
        beforeCreate() {
            this.titlePrefix = '采购单';
            this.caiGouDanXiangQingsModel = {
                caiGouDanID: "", // 采购单ID
                shangPinMC: "", // 商品名称
                shangPinID: "", // 商品ID
                guiGe: "", // 规格
                huoHao: "", // 货号
                tiaoMa: "", // 条码
                fenLei: "", // 分类
                danWei: "", // 单位
                shengChanRQ: "", // 生产日期
                baoZhiQiTian: "", // 保质期（天）
                caiGouSL: "", // 采购数量
                ruKuSL: "", // 入库数量
                jinHuoJiaHeJi: "", // 进货价合计
                jinHuoJia: "", // 进货价
                guiGeJiShu: "1", // 规格基数
                dianNeiLSJ: "", // 店内零售价
                beiZhu: "", // 备注
            }
            this.defaultForm = {
                id: null,
                caiGouDanID: "", // 采购单ID
                caoZuoRenID: this.$store.getters.user.id, // 操作人ID
                caoZuoRen: this.$store.getters.user.name, // 操作人
                caiGouLX: "", // 采购类型
                ruKuZT: "2", // 入库状态
                caiGouSJ: "", // 采购时间
                caoZuoSJ: "", // 操作时间
                gongHuoShang: "", // 供货商
                gongHuoShangID: "", // 供货商ID
                cangKu: "", // 仓库
                cangKuID: "", // 仓库ID
                heJi: "", // 合计（种）
                jinE: "", // 金额
                queRenStatus: "未确认", // 未确认
                beiZhu: "", // 备注
                caiGouDanXiangQings: [],
            };
            this.addService = add;
            this.updateService = update;
            this.getUpdateService = getUpdate;
        },
        data() {
            let {required, number, positiveEightTwo, digits} = ruleBuilder;
            this.cglx = DICT_TYPE_CAI_GOU_TYPE;
            return {
                rules: {
                    shengChanRQ: [required()], // 生产日期
                    caiGouLX: [required()], // 采购类型
                    gongHuoShang: [], // 供货商
                    baoZhiQiTian: [required(), number()], // 保质期（天）
                    caiGouSL: [required(), positiveEightTwo()], // 采购数量
                    jinHuoJia: [required(), positiveEightTwo()], // 进货价
                    caiGouDanID: [], // 采购单ID
                    caoZuoRen: [required()], // 操作人
                    caoZuoRenID: [required()], // 操作人ID
                    caiGouSJ: [], // 采购时间
                    caoZuoSJ: [], // 操作时间
                    gongHuoShangID: [], // 供货商ID
                    cangKu: [required()], // 仓库
                    cangKuID: [], // 仓库ID
                    heJi: [], // 合计（种）
                    jinE: [], // 金额
                    beiZhu: [], // 备注
                },
                options: [],
                spOptions: [],
                gongHuoShangOptions: [],
                chooseTable: [],
                isShowAddList: false,
                rkZT: TYPE_RU_KU_STATUS,
            }
        },
        computed: {
            showInputTime() {
                // 根据选择的入库类型判断是否需要显示入库时间表单项
                return this.form.caiGouLX === this.cglx.BCGD.key;
            }
        },
        mounted() {
            this.getCKList()
            this.getGHSList()
        },
        methods: {
            // 获取仓库下拉选择列表
            getCKList() {
                getCangKuList().then((response) => {
                    this.options = response.data
                })
            },
            getGHSList() {
                getGongHuoShangList().then((response) => {
                    this.gongHuoShangOptions = response.data
                })
            },
            handleClSelect(chuShiShuJv) {
                let cc = [];
                this.form.caiGouDanXiangQings.forEach(item => cc.push(item.shangPinID));
                chuShiShuJv.forEach(cl => {
                    let obj = {};
                    if (!cc.includes(cl.id)) {
                        obj.shangPinMC = cl.shangPinMC || '';
                        obj.shangPinID = cl.id || '';
                        obj.guiGe = cl.ciJiGuiGeMC || '';
                        obj.danWei = cl.shangPinDW || '';
                        obj.fenLei = cl.fenLeiMC || '';
                        obj.gongHuoShang = cl.gongHuoShangMC || '';
                        obj.gongHuoShangID = cl.gongHuoShang || '';
                        if (cl.shiFuDuoGuiGe === 'true') {
                            obj.jinHuoJia = cl.guiGeShangPinJinJia || '';
                            obj.huoHao = cl.guiGeHuoHao || '';
                            obj.tiaoMa = cl.guiGeTiaoMa || '';
                        } else {
                            obj.jinHuoJia = cl.shangPinJinJia || '';
                            obj.huoHao = cl.shangPinHuoHao || '';
                            obj.tiaoMa = cl.shangPinTiaoMa || '';
                        }
                        this.form.caiGouDanXiangQings.push(obj);
                    }
                });
            },
        }
    }
</script>

<style scoped>
    table {
        border-collapse: collapse;
        width: 100%;
    }

    th, td {
        border: 1px solid #dddddd;
        text-align: left;
        padding: 8px;
    }

    th {
        background-color: #f2f2f2; /* 设置表头背景颜色 */
    }

    .highlighted {
        background-color: yellow; /* 设置醒目颜色 */
    }
</style>
