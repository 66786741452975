<template>
    <el-dialog :title="title" :visible.sync="editor.visible" @open="open" :close-on-click-modal="false"
               destroy-on-close center append-to-body width="80%">
        <el-form ref="form" :model="form" :rules="rules" class="x-edit">
            <x-divider title="采购单详情"></x-divider>
              <x-row :rows="form.caiGouDanXiangQings" :rowModel="caiGouDanXiangQingsModel" no-add-btn no-clean-btn>
                <x-row-cell title="商品名称" read="shangPinMC">
                </x-row-cell>
                <x-row-cell title="采购数量" read="caiGouSL"/>
                <x-row-cell title="未入库数量" read="weiRuKuSL"/>
                <x-row-cell title="入库数量" prop="ruKuSL">
                    <el-input slot-scope="{row}" v-model="row.ruKuSL" size="small" maxlength="18"/>
                </x-row-cell>
                <x-row-cell title="参考进货价" prop="jinHuoJia">
                    <el-input slot-scope="{row}" v-model="row.jinHuoJia" size="small" maxlength="18"/>
                </x-row-cell>
                <x-row-cell title="生产日期" width="150" prop="shengChanRQ">
                    <el-input slot-scope="{row}" v-model="row.shengChanRQ" size="small" maxlength="8" type="date"
                              value-format="yyyy-MM-dd"/>
                </x-row-cell>
                <x-row-cell title="保质期（天）" prop="baoZhiQiTian">
                    <el-input slot-scope="{row}" v-model="row.baoZhiQiTian" size="small" maxlength="8"/>
                </x-row-cell>
                <x-row-cell title="规格" read="guiGe">
                </x-row-cell>
                <x-row-cell title="货号" read="huoHao">
                </x-row-cell>
                <x-row-cell title="条码" read="tiaoMa">
                </x-row-cell>
                <x-row-cell title="单位" read="danWei">
                </x-row-cell>

                <x-row-cell title="备注" prop="beiZhu">
                    <el-input slot-scope="{row}" v-model="row.beiZhu" size="small" maxlength="18"/>
                </x-row-cell>
            </x-row>

        </el-form>

        <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="save" icon="el-icon-document-add">保 存</el-button>
            <el-button @click="cancel" icon="el-icon-switch-button">取 消</el-button>
        </span>

    </el-dialog>


</template>

<script>
    import {add, getUpdate, updateRuKu} from "@/service/cggl/CaiGouDan";
    import XEditBase from "@/components/x/XEditBase";
    import {ruleBuilder} from '@/util/validate';
    import {DICT_TYPE_CAI_GOU_TYPE, TYPE_RU_KU_STATUS} from "@/util/constant";
    import ShangPinXXAllGuiGeSelector from "@/view/selector/ShangPinXXAllGuiGeSelector";

    export default {
        mixins: [XEditBase],
        components: {ShangPinXXAllGuiGeSelector},
        beforeCreate() {
            this.titlePrefix = '入库';
            this.caiGouDanXiangQingsModel = {
                caiGouDanID: "", // 采购单ID
                shangPinMC: "", // 商品名称
                shangPinID: "", // 商品ID
                guiGe: "", // 规格
                huoHao: "", // 货号
                tiaoMa: "", // 条码
                fenLei: "", // 分类
                danWei: "", // 单位
                shengChanRQ: "", // 生产日期
                baoZhiQiTian: "", // 保质期（天）
                caiGouSL: "", // 采购数量
                ruKuSL: "", // 入库数量
                jinHuoJiaHeJi: "", // 进货价合计
                jinHuoJia: "", // 进货价
                guiGeJiShu: "1", // 规格基数
                dianNeiLSJ: "", // 店内零售价
                beiZhu: "", // 备注
            }
            this.defaultForm = {
                id: null,
                caiGouDanID: "", // 采购单ID
                caoZuoRenID: this.$store.getters.user.id, // 操作人ID
                caoZuoRen: this.$store.getters.user.name, // 操作人
                caiGouLX: "", // 采购类型
                ruKuZT: "2", // 入库状态
                caiGouSJ: "", // 采购时间
                caoZuoSJ: "", // 操作时间
                gongHuoShang: "", // 供货商
                gongHuoShangID: "", // 供货商ID
                cangKu: "", // 仓库
                cangKuID: "", // 仓库ID
                heJi: "", // 合计（种）
                jinE: "", // 金额
                queRenStatus: "未确认", // 未确认
                beiZhu: "", // 备注
                caiGouDanXiangQings: [],
            };
            this.addService = add;
            this.updateService = updateRuKu;
            this.getUpdateService = getUpdate;
        },
        data() {
            let {required, number, positiveEightTwo, digits} = ruleBuilder;
            this.cglx = DICT_TYPE_CAI_GOU_TYPE;
            return {
                rules: {
                    shengChanRQ: [required()], // 生产日期
                    caiGouLX: [required()], // 采购类型
                    gongHuoShang: [], // 供货商
                    baoZhiQiTian: [required(), number()], // 保质期（天）
                    caiGouSL: [required(), positiveEightTwo()], // 采购数量
                    ruKuSL: [required(), positiveEightTwo()], // 入库数量
                    jinHuoJia: [required(), positiveEightTwo()], // 进货价
                    caiGouDanID: [], // 采购单ID
                    caoZuoRen: [required()], // 操作人
                    caoZuoRenID: [required()], // 操作人ID
                    caiGouSJ: [], // 采购时间
                    caoZuoSJ: [], // 操作时间
                    gongHuoShangID: [], // 供货商ID
                    cangKu: [required()], // 仓库
                    cangKuID: [], // 仓库ID
                    heJi: [], // 合计（种）
                    jinE: [], // 金额
                    beiZhu: [], // 备注
                },
                options: [],
                spOptions: [],
                gongHuoShangOptions: [],
                chooseTable: [],
                isShowAddList: false,
                rkZT: TYPE_RU_KU_STATUS,
            }
        },
        mounted() {
        },
        methods: {
        }
    }
</script>

<style scoped>
    table {
        border-collapse: collapse;
        width: 100%;
    }

    th, td {
        border: 1px solid #dddddd;
        text-align: left;
        padding: 8px;
    }

    th {
        background-color: #f2f2f2; /* 设置表头背景颜色 */
    }

    .highlighted {
        background-color: yellow; /* 设置醒目颜色 */
    }
</style>
